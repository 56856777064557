import React from 'react';

import style from './index.module.less';
import { useTranslation } from 'next-i18next';
import { Button } from 'antd';
import { useUserProvider } from '@/context/UserProvider';
import { ReportType } from '@/components/Common/CreateReportAlertContent';

const AllInContent = () => {
  const {
    onGetStartedClick,
    onOpenLoginModal,
    isLogin,
    onOpenCreateReportAlert,
    onCurrentReportType,
  } = useUserProvider();
  const { t } = useTranslation('common');

  const onDashboardClick = () => {
    if (isLogin) {
      onCurrentReportType(ReportType.COMPLEX);
      onOpenCreateReportAlert(true);
    } else {
      onGetStartedClick('Complex');
      onOpenLoginModal(true);
    }
  };
  return (
    <div className={style.content}>
      <h3 className={style.content__title}>{t('Get Your All-In-One Emergency Prep Now!')}</h3>
      <div className={style.content__desc}>
        {t(
          'It covers everything! Tailored to your local high-risk disasters and common emergencies, this guide includes 12 types of disasters or emergencies. It’s comprehensive and supports permanent editing.',
        )}
      </div>
      <div className={style['content__desc--reports']}>
        {new Array(6).fill('1').map((_, index) => {
          return (
            <div className={style.reportImg} key={index}>
              <img src={`/images/share/report${index + 1}.webp`} alt="" />
            </div>
          );
        })}
      </div>

      <div className={style.content__btnContent}>
        <Button className={style['content__btnContent--btn']} onClick={onDashboardClick}>
          {t('Get Started')}
        </Button>
      </div>
    </div>
  );
};

export default AllInContent;
